<script setup>
import { provide } from "vue";
import { useScreenTabs } from "./useScreenTabs";

const screenTabs = ref(useScreenTabs());

provide("screenTabs", screenTabs.value);

const goNext = (p) => screenTabs.value.goNext(p);
const goPrev = (p) => screenTabs.value.goPrev(p);
const goHome = (p) => screenTabs.value.goHome(p);

defineExpose({
  goNext,
  goPrev,
  goHome,
  currentTab: computed(() => screenTabs.value.currentTab),
});
</script>

<template>
  <div class="v-screen-tabs">
    <slot :goPrev="goPrev" :goNext="goNext" :goHome="goHome"></slot>
  </div>
</template>

<style scoped>
.v-screen-tabs {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 50%;
  top: 0;
  transform: translate(-50%);
  overscroll-behavior: contain;
  overflow: hidden;
  max-width: var(--width-mobile);
  box-sizing: border-box;
  height: calc(100% - var(--footer-height));
  /* margin-top: var(--header-height); */
  max-width: var(--screen-max-width);
}
</style>
